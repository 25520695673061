<template>

  <div class="row">
    <div class="col-12 card">
      <div class="row justify-content-space-between top-24">
        <h4 class="col-3 card-title">Настройки</h4>
        <div class="col-9 align-self-end right">
          <button class="col-3 btn-primary"><span class="icon-Plus"></span>Сохранить</button>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="top-24">
            <input type="checkbox" class="custom-checkbox" id="archive" name="happy" value="yes">
            <label for="archive">Технические работы на сайте</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Настройки"
    }
  },
};
</script>
