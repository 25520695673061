import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login/LoginPage.vue'




import guidesCreate from '../views/Guide/guidesCreate.vue'
import Settings from '../views/Settings.vue'


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/guides',
    children: [
      {
        path: '',
        name: 'Guides',
        component: () => import('../views/Guide/guidesList.vue'),
      },
      {
        path: 'create',
        component: guidesCreate,
      },
      {
        path: 'autos',
        component: () => import('../views/Guide/guidesAutosView.vue'),
      },
      {
        path: 'agents',
        component: () => import('../views/Guide/guidesAgentsView.vue'),
      },      
      {
        path: ':id/create',
        component: () => import('../views/Guide/guidesViewCreate.vue'),
      },
    ]
  },
  {
    path: '/analytics',
    children: [
      {
        path: '',
        name: 'Analytics',
        component: () => import('../views/Analytics/reportsList.vue'),
      },
      {
        path: 'create',
        component: () => import('../views/Analytics/reportsCreate.vue'),
      },
      {
        path: '1',
        component: () => import('../views/Analytics/reportsView1.vue'),
      },
      {
        path: '2',
        component: () => import('../views/Analytics/reportsView2.vue'),
      },
      {
        path: '3',
        component: () => import('../views/Analytics/reportsView3.vue'),
      },
      {
        path: '4',
        component: () => import('../views/Analytics/reportsView4.vue'),
      },
      {
        path: '5',
        component: () => import('../views/Analytics/reportsView5.vue'),
      },
      {
        path: '6',
        component: () => import('../views/Analytics/reportsView6.vue'),
      },
      {
        path: '7',
        component: () => import('../views/Analytics/reportsView7Stop.vue'),
      }
    ]
  },
  {
    path: '/reports',
    children: [
      {
        path: '',
        component: () => import('../views/Reports/ReportsMain.vue'),
      },
      {
        path: 'protection',
        children: [

          {
            path: ':id',
            component: () => import('../views/Reports/LaborProtection/View/protectionView.vue'),
          },
          {
            path: ':id/create',
            component: () => import('../views/Reports/LaborProtection/Create/protectionItemCreate.vue'),
          },
          {
            path: ':id/report/:id',
            component: () => import('../views/Reports/LaborProtection/View/protectionItemView.vue'),
          }
        ]
      },
      {
        path: 'appeal',
        children: [

          {
            path: ':id',
            component: () => import('../views/Reports/Appeal/View/AppealView.vue'),
          },
          {
            path: ':id/report/:id',
            component: () => import('../views/Reports/Appeal/View/AppealItemView.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/algorithms',
    children: [
      {
        path: '',
        name: 'Algorithms',
        component: () => import('../views/Algorithms/algorithmsList.vue'),
      },
      {
        path: 'create',
        name: 'AlgorithmsCreate',
        component: () => import('../views/Algorithms/algorithmsCreate.vue'),
      },
    ]
  },
  {
    path: '/addresses',
    children: [
      {
        path: '',
        name: 'Addresses',
        component: () => import('../views/Algorithms/addressesList.vue'),
      },
      {
        path: 'create',
        name: 'AddressesCreate',
        component: () => import('../views/Algorithms/addressesCreate.vue'),
      },
    ]
  },
  {
    path: '/sources',
    children: [
      {
        path: '',
        name: 'Sources',
        component: () => import('../views/Algorithms/sourcesList.vue'),
      },
      {
        path: 'create',
        name: 'SourcesCreate',
        component: () => import('../views/Algorithms/sourcesCreate.vue'),
      },
    ]
  },
  {
    path: '/users',
    children: [
      {
        path: '',
        name: 'Users',
        component: () => import('../views/Users/usersList.vue'),
      },
      {
        path: 'create',
        component: () => import('../views/Users/usersCreate.vue'),
      },
      {
        path: ':id/edit',
        component: () => import('../views/Users/usersEdit.vue'),
      }
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/home'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router
