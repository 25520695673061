import { createStore } from 'vuex'
import axios from 'axios'
import { auth } from './auth.module';

// let domain = DOMAIN
// let domain = 'https://3dmeta.cloud'

export default createStore({
  state: {
    showMenu: false,
    cars: [],
    companies: [],
    reports: [],
    checklists: [],
    answers: [],
    count_checklists: [],
    lastEquipmentData: [],
    appeals: [],
    stop_reports: [],
    production: [],
    statistic: [],
    // domain: 'http://localhost:8081',
    // domain: 'https://3dmeta.cloud',
    domain: 'https://amlogger-back.mag-rf.ru'
  },
  getters: {
    domain: state => state.domain,
  },
  mutations: {
    toggleShowMenu(state) {
      state.showMenu = !state.showMenu;
    },
    setCarData(state, carData) {
      state.cars = carData.map(car => {
        return {
          id: car.id,
          number: car.number,
          comment: car.comment,
          company: car.company
        };
      });
    },
    setCompanyData(state, companyData) {
      state.companies = companyData.map(company => {
        return {
          id: company.id,
          name: company.name,
          comment: company.comment,
        };
      });
    },
    setReportData(state, reportData) {
      state.reports = reportData.map(report => {
        return {
          id: report.id,
          title: report.title,
          car_necessary: report.car_necessary,
          period_necessary: report.period_necessary,
          finish_checklists: report.finish_checklists,
          questions: report.questions,
          comment: report.comment,
          group: report.group,
        };
      });
    },
    setLastEquipmentData(state, lastEquipmentData) {
      state.lastEquipmentData = lastEquipmentData.map(lastEquipmentData => {

        return {
          id: lastEquipmentData.id,
          name: lastEquipmentData.name,
          value: lastEquipmentData.value,
        };
      });
    },
    setAppealData(state, appealData) {
      state.appeals = appealData.map(appeal => {
        return {
          id: appeal.id,
          cid: appeal.kp_number,
          comment: appeal.comment,
          image: appeal.image,
          created_at: appeal.created_at
        };
      });
    },
    setStopReports(state, stopReportsData) {
      state.stop_reports = stopReportsData.map(stop_report => {
        return {
          id: stop_report.id,
          created_at: stop_report.created_at,
          finished_at: stop_report.finished_at,
          stop_period: stop_report.stop_period,
          status: stop_report.status,
        };
      });
    },
    setProductionData(state, productionData) {
      state.production = productionData.map(production => {
        return {
          id: production.id,
          batch: production.batch,
          product: production.product,
          weight: production.weight,
          created_at: production.created_at,
        };
      });
    },
    setChecklistData(state, checklistData) {
      state.checklists = checklistData.map(checklist => {
        return {
          id: checklist.id,
          report_title: checklist.report_title,
          company_name: checklist.company_name,
          car_number: checklist.car_number,
          answers: checklist.answers,
          finish: checklist.finish,
          period: checklist.period,
          created_at: checklist.created_at,
        };
      });
    },
    setAnswersData(state, answersData) {
      state.answers = answersData.map(answer => {
        return {
          id: answer.id,
          question: answer.question,
          comment: answer.comment,
          answer_result: answer.answer_result,
          image: answer.image,
          period_at: answer.period_at,
          created_at: answer.created_at,
        };
      });
    },
    setStatisticData(state, statisticData) {
      state.statistic = statisticData.map(statistic => {
        return {
          report_title: statistic.report_title,
          company_name: statistic.company_name,
          yes_answers: statistic.yes_answers_count,
          no_answers: statistic.no_answers_count,
          empty_answers: statistic.empty_answers_count,
          period: statistic.period,
        };
      });
    },
  },
  actions: {
    toggleShowMenu({ commit }) {
      commit('toggleShowMenu');
    },
    fetchCars({ commit, state }) {
      const baseURL = state.domain + "/api/cars/";
    
      axios
        .get(baseURL)
        .then(response => {
          commit("setCarData", response.data);
        })
        .catch(e => {
          console.log(e); 
        });
    },
    fetchCompanies({ commit, state }) {
      const baseURL = state.domain + "/api/companies/";
    
      axios
        .get(baseURL)
        .then(response => {
          commit("setCompanyData", response.data);
        })
        .catch(e => {
          console.log(e); 
        });
    },
    fetchReports({ commit, state }) {
      const baseURL = state.domain + "/api/protection/";
    
      axios
        .get(baseURL)
        .then(response => {
          commit("setReportData", response.data);
        })
        .catch(e => {
          console.log(e); 
        });
    },
    fetchAppeals({ commit, state }, date) {
      const baseURL = state.domain + "/api/appeals/";
      console.log(date[0])
      const params = {
        start_date: date[0],
        end_date: date[1]
      }
      axios
        .get(baseURL, { params })
        .then(response => {
          commit("setAppealData", response.data);
        })
        .catch(e => {
          console.log(e); 
        });
    },
    fetchLastEquipmentParams({ commit, state }) {
      const baseURL = state.domain + "/api/equipment/parameters/last";

      axios
        .get(baseURL)
        .then(response => {
          commit("setLastEquipmentData", response.data);
        })
        .catch(e => {
          console.log(e); 
        });
    },
    fetchChecklists({ commit, state }, reportId ) {
      if (typeof reportId != 'undefined') {
        const baseURL = state.domain + '/api/protection/'+reportId;
        axios
          .get(baseURL)
          .then(response => {
            commit("setChecklistData", response.data);
          })
          .catch(e => {
            console.log(e); 
          });
      } else {
        const baseURL = state.domain + '/api/checklists/';
        axios
          .get(baseURL)
          .then(response => {
            commit("setChecklistData", response.data);
          })
          .catch(e => {
            console.log(e); 
          });
      }
    },
    fetchAnswers({ commit, state }, ChecklistId ) {
        const baseURL = state.domain + '/api/answers/'+ChecklistId;
        axios
          .get(baseURL)
          .then(response => {
            commit("setAnswersData", response.data);
          })
          .catch(e => {
            console.log(e); 
          });
    },
    fetchStopReport({ commit, state }) {
      const baseURL = state.domain + '/api/stopreports/';
      axios
        .get(baseURL)
        .then(response => {
          commit("setStopReports", response.data);
        })
        .catch(e => {
          console.log(e); 
        });
    },
    fetchProduction({ commit, state }, date) {
      const baseURL = state.domain + '/api/production/';

      const params = {
        start_date: date[0],
        end_date: date[1]
      }
      // console.log(params) 
      axios
        .get(baseURL, { params })
        .then(response => {
          commit("setProductionData", response.data);
        })
        .catch(e => {
          console.log(e); 
        });
    },
    fetchStatistic({ commit, state }, date) {
      const baseURL = state.domain + '/api/statistic/';

      const params = {
        start_date: date[0],
        end_date: date[1]
      }
      axios
        .get(baseURL, { params })
        .then(response => {
          commit("setStatisticData", response.data);
        })
        .catch(e => {
          console.log(e); 
        });
    },
  },
  modules: {
    auth
  }
});


