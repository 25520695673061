<template>
      <div class="row justify-content-space-between">
        
        <div class="col-12">
          <strong class="col-5 card-title">Выбрать дату</strong>
          <date-picker class="btn-tab btn-tab-active right-space-16" v-model:value="datefilter" range></date-picker>
          <strong class="col-5 card-title">Год:</strong>
          <button class="btn-tab right-space-16" @click="setYear(['Sun Jan 01 2022 00:00:00', 'Sun Dec 31 2022 00:00:00'])">2022</button>
          <button class="btn-tab right-space-16" @click="setYear(['Sun Jan 01 2023 00:00:00', 'Sun Dec 31 2023 00:00:00'])">2023</button>
          <button class="btn-tab" @click="setYear(['Sun Jan 01 2024 00:00:00', 'Sun Dec 31 2024 00:00:00'])">2024</button>
        </div>
        <strong class="col-5 card-title" v-if="this.datefilter[0]">Период c {{ format_date(this.datefilter[0]) }} по {{ format_date(this.datefilter[1]) }}</strong>
      </div>
  <div class="row">
    
    <div class="col-6 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">Инспекции автопарка</strong>
      </div>
      <div class="col-12">
        <ProgressBar :uploadPercentage = uploadPercentage v-if = "uploadPercentage===Infinity"></ProgressBar>
        <DashChart v-else
          :companies_names="GetCompaniesNames" 
          :results="resultQuery"
          :report_title="'Безопасность автотранспорта'" 
          :first_answer_result="'Нет'" 
          :second_answer_result="''">
        </DashChart>
      </div>
    </div>
    <div class="col-6 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">Документарное соответствие (ОТиТБ) v2.0 (2023)</strong>
      </div>
      <div class="col-12">
        <ProgressBar :uploadPercentage = uploadPercentage v-if = "uploadPercentage===Infinity"></ProgressBar>
        <DashChart v-else
          :companies_names="GetCompaniesNames" 
          :results="resultQuery"
          :report_title="'Документация по ОТ v 2.0 (2023)'" 
          :first_answer_result="'Нет'" 
          :second_answer_result="''">
        </DashChart>
      </div>
    </div>
    <div class="col-6 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">Коэффициент аварийности</strong>
      </div>
      <div class="col-12">
        <ProgressBar :uploadPercentage = uploadPercentage v-if = "uploadPercentage===Infinity"></ProgressBar>
        <DashChart v-else
          :companies_names="GetCompaniesNames" 
          :results="resultQuery"
          :report_title="'ДТП'" 
          :first_answer_result="'Да'" 
          :second_answer_result="''">
        </DashChart>
      </div>
    </div>
    <div class="col-6 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">Соответствие матбазы</strong>
      </div>
      <div class="col-12">
        <ProgressBar :uploadPercentage = uploadPercentage v-if = "uploadPercentage===Infinity"></ProgressBar>
        <DashChart v-else
          :companies_names="GetCompaniesNames" 
          :results="resultQuery"
          :report_title="'Соответствие матбазы'" 
          :first_answer_result="'Да'" 
          :second_answer_result="''">
        </DashChart>
      </div>
    </div>
    <div class="col-6 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">Кол-во НС / серьезных аварий</strong>
      </div>
      <div class="col-12">
        <ProgressBar :uploadPercentage = uploadPercentage v-if = "uploadPercentage===Infinity"></ProgressBar>
        <DashChart v-else
          :companies_names="GetCompaniesNames" 
          :results="resultQuery"
          :report_title="'НС и серьёзные аварии'" 
          :first_answer_result="'Да'" 
          :second_answer_result="''">
        </DashChart>
      </div>
    </div>
    <div class="col-6 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">Документарное соответствие (ОТиТБ) v1.0 (2022)</strong>
      </div>
      <div class="col-12">
        <ProgressBar :uploadPercentage = uploadPercentage v-if = "uploadPercentage===Infinity"></ProgressBar>
        <DashChart v-else
          :companies_names="GetCompaniesNames" 
          :results="resultQuery"
          :report_title="'Документация по ОТ v 1.0 (2022)'" 
          :first_answer_result="'нет'" 
          :second_answer_result="''">
        </DashChart>
      </div>
    </div>
  </div>
</template>

<script>
import DashChart from "@/components/Dashbord/DashChart.vue";
import DatePicker from 'vue-datepicker-next';
import ProgressBar from "@/components/Shared/ProgressBar.vue";
import moment from 'moment'

export default {
  components: { DashChart, DatePicker, ProgressBar },
  data() {
    return {
      showSearch: false,
      title: "Дашборд",
      datefilter: ['2022-10-01', '2023-12-31'],
      search: null,
      year: '',
      FilteredChecklists: [],
      // uploadPercentage: 15,
    }
  },
  computed: {
    // Checklists() {
    //   return this.$store.state.count_checklists.filter((checklist) => checklist.finish==true)
    // },
    Checklists() {
      return this.$store.state.statistic
    },
    uploadPercentage() {
      return 100/this.$store.state.statistic.length*1000
    },

    resultQuery() {
      
      this.countArray = []
      const byDate = (item) => {
          const itemDate = new Date(item.period);
          return (
          itemDate >= new Date(this.datefilter[0]) &&
          itemDate <= new Date(this.datefilter[1])
          );
      };

      const byTitle = (item) =>
          item.title.toLowerCase().includes(this.search.toLowerCase());

      let results = this.Checklists;

      if (this.search) {
          results = results.filter(byTitle);
      }
      const hasDateFilter =
          this.datefilter?.length >= 2 &&
          this.datefilter[0] &&
          this.datefilter[1];
      if (hasDateFilter) {
          results = results.filter(byDate);
      }

      // console.log(results)
      return results;
    },

    GetCompanies() {
      return this.$store.state.companies
    },
    GetCompaniesNames() {
      return this.GetCompanies.map(company => company.name)
    },
  },
  async created() {
        this.$store.dispatch("fetchCompanies");
        this.$store.dispatch("fetchStatistic", [moment(String(this.datefilter[0])).format('YYYY-MM-DD'), moment(String(this.datefilter[1])).format('YYYY-MM-DD')]);

  },
  methods: {
    setYear(year){
      new Date(this.datefilter[0] = year[0])
      new Date(this.datefilter[1] = year[1])
      this.year = year
      return
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('DD.MM.YYYY')
      }
    },
  }
};
</script>
