import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VeeValidate from 'vee-validate';
import VueApexCharts from "vue3-apexcharts";
import VueSpeedometer from "vue-speedometer";
import './assets/style/grid.css'
import './assets/style/styles.scss'


createApp(App).use(store).use(router).use(VueApexCharts, VeeValidate, VueSpeedometer, axios).mount('#app')
