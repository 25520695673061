<template>
    <div class="grid">
      <div class="aside" :class="{ 'hide-tablet-landscape': !this.$store.state.showMenu}" v-if="currentUser">
        <Aside/>
      </div>
      <div class="main">
        <div class="container">
          <div class="row" v-if="currentUser"><Header/></div>
          <div class="row"><Content/></div>
          
        </div>
        
      </div>
    </div>
    <span class="scrollToTop" @click="scrollToTop">^</span>
</template>

<script>
import Header from "@/components/Header.vue";
import Aside from "@/components/Aside.vue";
import Content from "@/components/Content.vue";

export default {
  components: {
      Header, Aside, Content
  },
  computed : {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  created() {
      // this.$store.dispatch("fetchCompanies");
      // this.$store.dispatch("fetchChecklists");
  },
  methods: {
  scrollToTop() {
    window.scrollTo(0,0);
  }
}
}
</script>

<style scoped>

.scrollToTop {
  position: sticky;

  bottom: 15px;
  background-color: #1B69B2;
  border: none;
  color: white;
  padding: 15px 20px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  display: inline-block;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 12px;
}
.scrollToTop:hover{
  background-color: #0E4C85;
   color:white;
}
</style>
