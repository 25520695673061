<template>
        <table width="100%" >
          <thead>
            <th>
              Номер партии
            </th>
            <th>
              Продукция
            </th>
            <th>
              Вес (кг)
            </th>
            <th>
              Дата производства
            </th>
          </thead>
          <tbody>
            <tr class="bottom" v-for="batch in production"
              :key="batch.id"
              :batch="batch.batch"
              :weight="batch.weight"
              :product="batch.product"
              :created_at="batch.created_at"
              >
                <td >
                  {{ batch.batch }}
                </td>
                <td style="text-align: center;">
                  {{ batch.product }}
                </td>
                <td style="text-align: center;">
                  {{ batch.weight }}
                </td>
                <td style="text-align: center;">
                  {{ format_date(batch.created_at) }}
                </td>
            </tr>
          </tbody>
        </table>
</template>
<script>
import moment from 'moment'

export default {
    props: ['production'],
    data() {
        return {

        }
    },
    methods: {
      format_date(value){
        if (value) {
          return moment(String(value)).format('DD.MM.YYYY HH:mm')
        }
      },
    }
}
</script>