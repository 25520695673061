<template>
    <div class="row">
        <router-link to="/"><img class="logo" src="/img/logo_mag.svg" alt=""></router-link>
    </div>
    <div class="row">
      <ul class="aside-ul">
        <router-link to="/">
          <li class="aside-li">
            <span class="icon-HouseLine"></span>
            Дашборд
          </li>
        </router-link>
        <!-- <router-link to="/disp" v-if="currentUser.groups.includes('Диспетчерская')">
          <li class="aside-li">
            <span class="icon-list"></span>
            Дашборд Диспетчерская
          </li>
        </router-link> -->
        <router-link to="/analytics">
          <li class="aside-li">
            <span class="icon-File"></span>
            Аналитика
          </li>
        </router-link>
        <router-link to="/reports">
          <li class="aside-li">
            <span class="icon-File"></span>
            Отчёты
          </li>
        </router-link>
        <!-- <li class="aside-li" v-on:click="dropDown=!dropDown">
          <span class="icon-Graph"></span>
          Алгоритмы
          <span class="icon-CaretDown"></span>

        </li>

          <ul class="aside-open-ul">
            <router-link to="/algorithms">
              <li class="aside-open-li" :class="{ 'close' : !dropDown }">
                <span class="icon-CaretRight"></span>
                Обработчики
              </li>
            </router-link>
            <router-link to="/sources">
              <li class="aside-open-li" :class="{ 'close' : !dropDown }">
                <span class="icon-CaretRight"></span>
                Источники
              </li>
            </router-link>
            <router-link to="/addresses">
              <li class="aside-open-li" :class="{ 'close' : !dropDown }">
                <span class="icon-CaretRight"></span>
                Адресаты
              </li>
            </router-link>
          </ul>
        <router-link to="/users">
          <li class="aside-li">
            <span class="icon-Users"></span>
            Пользователи
          </li>
        </router-link>
        <router-link to="/settings">
          <li class="aside-li">
            <span class="icon-SlidersHorizontal"></span>
            Настройки
          </li>
        </router-link> -->
      </ul>
    </div>
</template>

<script>
export default {
  // data() {
  //   return {
  //     dropDown: false,
  //   },
  // },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
}
</script>