<template>
        <table width="100%" >
          <thead>
            <th>
              Готовая продукция
            </th>
            <th>
              Вес (кг)
            </th>
          </thead>
          <tbody>
            <tr class="bottom" v-for="(product, index) in getProducts"
              :key="index"
              >
                <td >
                  {{ product }}
                </td>
                <td style="text-align: center;">
                    {{ batchs(product) }}
                </td>
            </tr>
          </tbody>
        </table>

</template>

<script>
export default {
    props: ['production'],
    data() {
        return {

        }
    },
    
    computed: {
        getProducts() {
        const products = new Set();
        this.production.forEach(item => products.add(item.product))
        return Array.from(products); 
        },
    },
    methods: {
        batchs(product) {
            let sum_array = this.$store.state.production
                .filter(item => item.product === product)
                .map(item => parseInt(item.weight));
            return sum_array.reduce(function(sum_array, num){ return sum_array + num }, 0)
        },
    }
}
</script>