import axios from 'axios';

// const API_URL = 'https://3dmeta.cloud/api/token/'
const API_URL = 'https://amlogger-back.mag-rf.ru/api/token/'
// const API_URL = 'http://localhost:8081/api/token/';

class AuthService {
  login(user) {
    return axios
      .post(API_URL, {
        username: user.username,
        password: user.password
      })
      .then(response => {
        if (response.data.access) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
  }

  register(user) {
    return axios.post(API_URL + 'signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}

export default new AuthService();