<template>
    
    <button class="btn-tab" @click="chartType = 'area'">График</button>
    <button class="btn-tab" @click="chartType = 'bar'">Диаграмма</button>
    <apexchart ref="realtimeChart" type="bar" :options="options" :series="series" v-if="chartType == 'bar'"></apexchart>
    <apexchart ref="realtimeChart" type="area" :options="options" :series="series" v-if="chartType == 'area'"></apexchart>
</template>

<script>
export default {
    data() {
        return {
            chartType: 'bar',
            checklists: this.results.filter((checklist) => checklist.report_title==this.report_title),
            countArray: [],
            options: {
                // chart: {
                //     id: 'vuechart-example'
                // },
                stroke: {
                    curve: 'smooth',
                    colors: ['#F26650'],
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    colors: '#F26650',
                    type: 'solid',
                    opacity: 0.1,
                },
            },
            series: [{
                name: 'Инциденты',
                // data: [30, 27, 26, 21, 15, 11, 7, 2]
            }]
        }
    },
    props: ['companies_names', 'results', 'report_title', 'first_answer_result', 'second_answer_result'],
    mounted() {
        this.updateOptions()
    },
    watch: {
        results: {
            immediate: true, 
            handler (val, oldVal) {
                this.countArray = []
                this.createCountArray(this.results.filter((checklist) => checklist.report_title==this.report_title), this.first_answer_result, this.second_answer_result)
            }
        }
    },
    computed: {

    },
    methods: {
        createCountArray(results, first_answer_result, second_answer_result) {
            this.companies_names.forEach(company_name => {
                let amount = 0

                let companyChecklists = results.filter((checklist) => {
                    return checklist.company_name.includes(company_name)
                })
                companyChecklists.forEach(function(checklist) {
                    
                    if (first_answer_result == 'Нет' | first_answer_result == 'нет') {
                        amount += checklist.no_answers
                    } else if (first_answer_result == 'Да' | first_answer_result == 'да') {
                        amount += checklist.yes_answers
                    }
                })
                this.countArray.push(amount)
            })
            this.updateSeries()
        },
        // createCountArray(results, first_answer_result, second_answer_result) {
        //     this.companies_names.forEach(company_name => {
        //         let amount = 0

        //         let companyChecklists = results.filter((checklist) => {
        //             return checklist.company_name.includes(company_name)
        //         })
        //         companyChecklists.forEach(function(checklist) { 
        //             checklist.answers.forEach(function(answer) {
        //                 if (answer.answer_result == first_answer_result | answer.answer_result == second_answer_result) {
        //                     amount += 1
        //                 }
        //             })
        //         })

        //         this.countArray.push(amount)
        //     })
        //     this.updateSeries()
        // },
        updateSeries(){
            
            this.series = [
                {
                    name: 'Инциденты',
                    data: this.countArray
                },
            ];
        },
        updateOptions(){
            this.options = {
                fill: {
                    colors: '#F26650',
                    type: 'solid',
                    opacity: 0.5,
                },
                xaxis: {
                    categories: this.companies_names
                }
            }
        },
    }
}
</script>