<template>
    <div class="header col-12 hide-phone">
    <!-- <div class="header col-12 " v-if="showMenu"> -->
      <div class="row no-children-v-gutters justify-content-space-between">
        <div class="page-title col-3"><h4>{{ title }}</h4></div>
        <div class="right col-8 align-self-end" v-if="currentUser">
          <!-- <span class="icon-bell right-space-24"></span> -->
          <span class="avatar right-space-16">{{ currentUser.username.substr(0, 2).toUpperCase() }}</span>
          <span class="right-space-16">{{ currentUser.username }} ({{ currentUser.groups[0] }})</span>
          
          <span class="logout"> | <a @click="logout">Выйти</a></span>
          <!-- <span v-if="isLoggedIn"> | <a @click="logout">Logout</a></span> -->
        </div>
      </div>
    </div>
    <div class="header col-12 hide show-phone no-v-gutters" >
    <!-- <div class="header col-12 hide show-phone no-v-gutters" v-if="!showMenu"> -->
      <div class="row no-children-v-gutters justify-content-space-between">
        <div class="col-4" >
          <span class="icon-list" style="font-size: 32px;" @click="toggle()"></span>
        </div>
        <div class="col-4">
          <router-link to="/"><img class="logo" src="img/logo_mag.png" alt="" height="20"></router-link>
        </div>
        <div class="right col-4">
          <span class="avatar" style="position: relative; top:15px;">АП</span>
        </div>
      </div>
    </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Дашборд",
    }
  },
  computed : {
      currentUser() {
        return this.$store.state.auth.user;
      },
      // showMenu() {
      //   return this.$store.state.showMenu;
      // }
  },
  methods: {
    logout: function () {
      this.$store.dispatch('auth/logout')
      .then(() => {
        this.$router.push('/login')
      })
    },
    toggle() {
        this.$store.dispatch('toggleShowMenu');
    }

  },
};
</script>

<style scoped>

</style>