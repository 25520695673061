<template>

  <div class="row">
    <div class="col-4 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-11 card-title"><span class="icon-Bookmark"></span>Работа пакетовскрывателей</strong>
        <!-- <div class="right col-3 align-self-end"><button class="btn-circle"><span class="icon-Plus" style="top: -1px; left: -1px"></span></button></div> -->
      </div>
      <div class="row ">
        <!-- <div class="col-12">
          <small>Пакетовскрыватель:</small><br>
          <strong>Пакетовскрыватель 1</strong>
        </div> -->
        <div class="col-12">
          <small>Пакетовскрыватель №1:</small><br>
          <!-- <strong>31.05.2023 13:49:35</strong> -->
          <div class="row no-children-v-gutters">
            <div class="col-2">
              <span v-bind:class="getIcon(getPowerBAG1)" style="font-size: 3em; top: -1px; left: -1px"></span>
            </div>
            <div class="col-5">
              <div class="row">
                <strong class="number ">Загрузка: </strong>
                <strong v-bind:class="getClass(getSensorBAG1)">{{ getSensorBAG1 }}%</strong>
              </div>
              <div class="row">
                <strong class="number ">Мощность: </strong>
                <strong v-bind:class="getClass(getPowerBAG1)">{{ getPowerBAG1 }}%</strong>
              </div>
            </div>
            <div class="col-3">
              <button class="btn-secondary" @click="clickCam('192.168.177.199')"><span class="icon-Camera" style="left: 4px;"></span></button>
            </div>
          </div>
        </div>
        <div class="col-12">
          <small>Пакетовскрыватель №2:</small><br>
          <!-- <strong>31.05.2023 13:49:35</strong> -->
          <div class="row no-children-v-gutters">
            <div class="col-2">
              <span v-bind:class="getIcon(getPowerBAG2)" style="font-size: 3em; top: -1px; left: -1px"></span>
            </div>
            <div class="col-5">
              <div class="row">
                <strong class="number ">Загрузка: </strong>
                <strong v-bind:class="getClass(getSensorBAG2)">{{ getSensorBAG2 }}%</strong>
              </div>
              <div class="row">
                <strong class="number ">Мощность: </strong>
                <strong v-bind:class="getClass(getPowerBAG2)">{{ getPowerBAG2 }}%</strong>
              </div>
            </div>
            <div class="col-3">
              <button class="btn-secondary" @click="clickCam('192.168.177.133')"><span class="icon-Camera" style="left: 4px;"></span></button>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="row top-24">
        <button class="col-12 btn-primary"><span class="icon-DownloadSimple"></span>Отчёт</button>
      </div> -->
    </div>
    
    <div class="col-4 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-11 card-title"><span class="icon-Lifebuoy"></span>Производительность завода</strong>
        <!-- <div class="right col-3 align-self-end"><button class="btn-circle"><span class="icon-Plus" style="top: -1px; left: -1px"></span></button></div> -->
      </div>
      <div class="row ">
        <div class="col-12" style="text-align: center;">{{ this.getPower() }}
          <vue-speedometer :style="styles" :maxValue="100" :value="power"/>
        </div>
      </div>
      <!-- <div class="row top-24">
        <button class="col-6 btn-primary"><span class="icon-DownloadSimple"></span>Отчёт</button>
        <button class="col-6 btn-secondary" @click="clickCam('192.168.178.149')"><span class="icon-Camera"></span>Камера</button>
      </div> -->
    </div>

      <div class="col-4 card col-laptop-12">
        <div class="row justify-content-space-between">
          <strong class="card-title"><span class="icon-Bookmark"></span>Ремонты</strong>
          <div class="col-8 right">
          <button class="btn-tab btn-tab-active right-space-16">Актуальные</button>
          <button class="btn-tab">Завершённые</button>
        </div>
        </div>
        <!-- <div class="row ">
          <div class="col-12">
            <small>Документ: 245</small><br>
            <small class="gray">Замена вала</small>
          </div>
        </div>
        <div class="row ">
          <div class="col-12">
            <small>Документ: 244</small><br>
            <small class="gray">Замена датчика температуры №23 454 на чиллере №2</small>
          </div>
        </div>
        <div class="row ">
          <div class="col-12">
            <small>Документ: 243</small><br>
            <small class="gray">Замена датчика температуры №23 454 на чиллере №2</small>
          </div>
        </div>
        <div class="row ">
          <div class="col-12">
            <small>Документ: 242</small><br>
            <small class="gray">Замена датчика температуры №23 454 на чиллере №2</small>
          </div>
        </div>
        <div class="row top-24">
          <button class="col-12 btn-primary"><span class="icon-DownloadSimple"></span>Отчёт</button>
          <button class="col-6 btn-secondary" @click="clickCam('192.168.178.149')"><span class="icon-Camera"></span>Камера</button>
        </div> -->
      </div>
  </div>
  <div class="row">
    <div class="col-8 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">Производство готовой продукции 
          <br><small v-if="this.datefilter[0]">c {{ format_date(this.datefilter[0], 'date') }} по {{ format_date(this.datefilter[1], 'date') }}</small>
        </strong>

        <div class="col-6 right">
          <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'byTotal'}" @click="currentTab = 'byTotal'">Всего произведено</button>
          <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'byBatchs'}" @click="currentTab = 'byBatchs'">Произведено по партиям</button>
        </div>
      </div>
      <div class="col-12">
        <date-picker class="btn-tab btn-tab-active right-space-16" v-model:value="datefilter" range></date-picker>
        <div>
          <byTotal v-if="currentTab === 'byTotal'" :production="resultQuery"/>
          <byBatchs v-if="currentTab === 'byBatchs'" :production="resultQuery"/>
        </div>
      </div>
    </div>
    <div class="col-4 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-8 card-title">Простои <br>(За сегодня {{ totalStopPeriod }})</strong>
      </div>
        
        <div class="col-12">
          <table width="100%" >

            <tbody>
              <tr class="bottom" v-for="stop_report in getStopReports"
                :key="stop_report.id"
                :id="stop_report.id"
                :created_at="stop_report.created_at"
                :finished_at="stop_report.finished_at"
                :stop_period="stop_report.stop_period"
                :status="stop_report.status"
                >
                  <td >
                    <small>Документ: {{ stop_report.id }}</small><br>
                    <small class="gray">{{ format_date(stop_report.created_at) }} -> {{ format_date(stop_report.finished_at) }}</small>
                  </td>
                  <td style="text-align: center;">
                    <small style="position: relative;">Время простоя: {{ stop_report.stop_period }}</small>
                  </td>
              </tr>
            </tbody>
          </table>
          <div class="row top-24">
            <router-link to="/analytics/7" class="col-12 btn-primary"><span class="icon-DownloadSimple"></span>Отчёт</router-link>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import byBatchs from "@/components/Dashbord/HomeFactory/byBatchs.vue";
import byTotal from "@/components/Dashbord/HomeFactory/byTotal.vue";
import DatePicker from 'vue-datepicker-next';
import VueSpeedometer from "vue-speedometer"
import moment from 'moment'

export default {
  components: { VueSpeedometer, DatePicker, byBatchs, byTotal },
  data() {
    return {
      power: 0,
      products: 0,
      currentTab: 'byTotal',
      datefilter: [this.today_date(), this.tomorrow_date()],
      timer: '',
      styles: {
        padding: "15px",
        height: "150px",
      },
      
      showSearch: false,
      title: "Дашборд",

      options: {

        chart: {
          id: 'vuechart-example'
        },
        stroke: {
          curve: 'smooth',
          colors: ['#F26650', '#000000'],
        },
        // markers: {
        //     size: 8,
        //     offsetX: 0,
        //     strokeColors: '#fff',
        //     strokeWidth: 5,
        //     colors: '#F26650',
        // },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: ['#F26650', '#000000'],
          type: 'solid',
          opacity: 0.1,
        },
        xaxis: {
          categories: [20, 21, 22, 23, 24, 25, 26]
        }
      },

      series: [
        {
          name: 'ПЭТ',
          color: '#F26650',
          data: [20, 0, 5, 18, 0, 20, 0]
        },
        {
          name: 'Картон',
          color: '#000000',
          data: [0, 10, 15, 8, 2, 0, 0]
        },    
    ]
    }
  },
  beforeMount() { document.title = this.title },
  mounted() {
    this.$store.dispatch("fetchLastEquipmentParams")
  },
  computed: {
    getLastEquipmentData() {
      // return this.$store.state.appeals.filter((appeal) => new Date(appeal.created_at) > new Date('2024-03-06'))
      return this.$store.state.lastEquipmentData
    },
    getSensorBAG1() {
      this.timer
      if (this.getLastEquipmentData.find(x => x.name == 'SensorBAG1')?.value !== 'NaN') {
        return this.getLastEquipmentData.find(x => x.name == 'SensorBAG1')?.value
      } else {
        return 0
      }
    },
    getPowerBAG1() {
      if (this.getLastEquipmentData.find(x => x.name == 'PowerBAG1')?.value !== 'NaN') {
        return this.getLastEquipmentData.find(x => x.name == 'PowerBAG1')?.value
      } else {
        return 0
      }
    },
    getSensorBAG2() {
      if (this.getLastEquipmentData.find(x => x.name == 'SensorBAG2')?.value !== 'NaN')  {
        return this.getLastEquipmentData.find(x => x.name == 'SensorBAG2')?.value
      } else {
        return 0
      }
    },
    getPowerBAG2() {
      if (this.getLastEquipmentData.find(x => x.name == 'PowerBAG2')?.value !== 'NaN') {
        return this.getLastEquipmentData.find(x => x.name == 'PowerBAG2')?.value
      } else {
        return 0
      }
    },
    getStopReports() {
      // return this.$store.state.appeals.filter((appeal) => new Date(appeal.created_at) > new Date('2024-03-06'))
      return this.$store.state.stop_reports.slice(0, 8)
    },
    getProduction() {
      return this.$store.state.production
    },
    resultQuery() {
      
      const byDate = (item) => {
          const itemDate = new Date(item.created_at);
          return (
          itemDate >= new Date(this.datefilter[0]) &&
          itemDate <= new Date(this.datefilter[1])
          );
      };

      let results = this.getProduction;

      const hasDateFilter =
          this.datefilter?.length >= 2 &&
          this.datefilter[0] &&
          this.datefilter[1];
      if (hasDateFilter) {
        this.$store.dispatch("fetchProduction", [moment(String(this.datefilter[0])).format('YYYY-MM-DD'), moment(String(this.datefilter[1])).format('YYYY-MM-DD')]);
        let results = this.getProduction;
        results = results.filter(byDate);
      }

      // console.log(results)
      return results;
    },
    totalStopPeriod() {
      let stop_period_array = this.$store.state.stop_reports.filter((stop) => stop.created_at > this.today_date()).map(stop => stop.stop_period)
      // console.log(this.today_date())
      // console.log(stop_period_array)
      
      let time_array = []

      stop_period_array.forEach(function(item) {
         item = item.split(':');
         let hours = parseInt(item[0]) * 60
         let minutes = parseInt(item[1])
         let full_period = parseInt(hours) + parseInt(minutes)
         time_array.push(parseInt(full_period))

        //  console.log(full_period)
      });

      let minutes = time_array.reduce(function(time_array, num){ return time_array + num }, 0)

      var m = minutes % 60;

      var h = (minutes-m)/60;

      var HHMM = (h < 10 ? "0" : "") + h.toString() + ":" + (m < 10 ? "0" : "") + m.toString();
      
      return HHMM
    },
  },
  methods: {
    clickCam(ip) {
      window.open('http://' + ip + '/action/stream?subject=mjpeg&user=admin&pwd=admin')
    },
    getPower() {
      if ((this.getPowerBAG1 !== 'NaN') & (this.getPowerBAG2 !== 'NaN')) {
        this.power = (parseInt(this.getPowerBAG1) + parseInt(this.getPowerBAG2)) / 2
      } else {
        this.power = 0
      }
    },
    getClass(p=0){
        return {
            'green': p >= 80,
            'orange': p >= 50 & p < 80,
            'red': p < 50
          }
    },
    getIcon(p=0){
        return {
            'icon-Check green': p > 5,
            'icon-X red': p <= 5
          }
    },
    fetchList () {
      this.$store.dispatch("fetchLastEquipmentParams")
      return this.$store.state.lastEquipmentData
    },
    cancelAutoUpdate () {
      clearInterval(this.timer);
    },
    format_date(value, time='NaN'){
      if (value) {
        if (time != 'NaN') {
          return moment(String(value)).format('DD.MM.YYYY')
        } else {
          return moment(String(value)).format('DD.MM.YYYY HH:mm')
        }
      }
    },
    today_date(){
      const today = new Date();
      const date = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+today.getDate();
      return date
    },
    tomorrow_date(){
      const today = new Date();
      const date = today.getFullYear()+'-0'+(today.getMonth()+1)+'-'+(today.getDate()+1);
      return date
    },

  },
  created() {
    this.$store.dispatch("fetchStopReport");

    this.$store.dispatch("fetchProduction", [moment(String(this.datefilter[0])).format('YYYY-MM-DD'), moment(String(this.datefilter[1])).format('YYYY-MM-DD')]);
    this.timer = setInterval(this.fetchList, 60000);
  },
  beforeUnmount () {
    this.cancelAutoUpdate();
  }


};
</script>
