<template>

  <div class="row">
    <div class="col-4 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-7 card-title"><span class="icon-ArrowRight"></span>Въезд на полигон</strong>
        <div class="right col-3 align-self-end"><button class="btn-circle"><span class="icon-Plus" style="top: -1px; left: -1px"></span></button></div>
      </div>
      <div class="row ">
        <div class="col-12">
          <small>Автомобиль:</small><br>
          <strong>Камаз МСТ-6963-50-4 PO73KM15</strong>
        </div>
        <div class="col-12">
          <small>Распознавание номера:</small><br>
          <strong>31.05.2023 13:49:35</strong>
          <div class="row no-children-v-gutters">
            <img src="img/number.png" alt="" class="number col-4">
            <strong class="col-7 green top-4">98%</strong>
          </div>
        </div>
        <div class="col-12">
          <small>Регистрация рейса::</small><br>
          <strong>А 515 CA 193</strong>
        </div>
      </div>
      <div class="row top-24">
        <button class="col-6 btn-primary"><span class="icon-DownloadSimple"></span>Данные</button>
        <button class="col-6 btn-secondary" @click="clickCam('192.168.178.149')"><span class="icon-Camera"></span>Камера</button>
      </div>
    </div>
    <div class="col-4 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-7 card-title"><span class="icon-Weight"></span>Весы</strong>
        <div class="col-3 align-self-end right"><button class="btn-circle"><span class="icon-Plus" style="top: -1px; left: -1px"></span></button></div>
      </div>
      <div class="row ">
        <div class="col-12">
          <small>Автомобиль:</small><br>
          <strong>Камаз МСТ-6963-50-4 PO73KM15</strong>
        </div>
        <div class="col-12">
          <small>Распознавание номера:</small><br>
          <strong>31.05.2023 13:49:35</strong>
          <div class="row no-children-v-gutters">
            <img src="img/number.png" alt="" class="number col-4">
            <strong class="col-7 green top-4">98%</strong>
          </div>
        </div>
        <div class="col-12">
          <small>Регистрация рейса::</small><br>
          <strong>А 515 CA 193</strong><br>
          <strong>15 245 кг</strong>
        </div>
      </div>
      <div class="row">
        <button class="col-6 btn-primary"><span class="icon-DownloadSimple"></span>Данные</button>
        <button class="col-6 btn-secondary" @click="clickCam('192.168.178.148')"><span class="icon-Camera"></span>Камера</button>
      </div>
    </div>
    
    <div class="col-4 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-7 card-title"><span class="icon-ArrowLeft"></span>Выезд с полигона</strong>
        <div class="col-3 align-self-end right"><button class="btn-circle"><span class="icon-Plus" style="top: -1px; left: -1px"></span></button></div>
      </div>
      <div class="row ">
        <div class="col-12">
          <small>Автомобиль:</small><br>
          <strong>Камаз МСТ-6963-50-4 PO73KM15</strong>
        </div>
        <div class="col-12">
          <small>Распознавание номера:</small><br>
          <strong>31.05.2023 13:49:35</strong>
          <div class="row no-children-v-gutters">
            <img src="img/number.png" alt="" class="number col-4">
            <strong class="col-7 green top-4">98%</strong>
          </div>
        </div>
        <div class="col-12">
          <small>Регистрация рейса::</small><br>
          <strong>А 515 CA 193</strong>
        </div>
      </div>
      <div class="row top-24">
        <button class="col-6 btn-primary"><span class="icon-DownloadSimple"></span>Данные</button>
        <button class="col-6 btn-secondary" @click="clickCam('192.168.178.147')"><span class="icon-Camera"></span>Камера</button>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-8 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">График въезда</strong>
        <div class="col-6 right">
          <button class="btn-tab btn-tab-active right-space-16">Неделя</button>
          <button class="btn-tab">Месяц</button>
        </div>
      </div>
      <div class="col-12">
        <apexchart type="area" :options="options" :series="series"></apexchart>
      </div>
    </div>
    <div class="col-4 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-8 card-title">Машины на территории</strong>
        <div class="col-3 align-self-end right"><button class="btn-circle" v-on:click="showSearch=!showSearch"><span class="icon-MagnifyingGlass" style="top: -1px; left: -1px"></span></button></div>
      </div>
      <div class="row" v-show="showSearch">
        <div class="col-12">
          <div class="text-field">
            <div class="text-field__icon">
              <input class="text-field__search" type="search" name="search" id="search" placeholder="Поиск по госномеру" value="">
              <span class="text-field__aicon">
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"><path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" /></svg>
              </span>
            </div>
          </div>
        </div>
      </div>
        <div class="col-12">
          <table width="100%">
            <thead>
              <th></th>
              <th></th>
            </thead>
            <tbody>
              <tr class="bottom">
                <td>
                  <small class="gray">26.06.2023 13:54:55</small>
                  <br>
                  <span class="car-number">Р 342 АН 152</span>
                  <br><br>
                  <small>Документ: 23 345</small>
                </td>
                <td>
                  <small style="position: relative; top: 32px;">Время: 01:45</small>
                </td>
              </tr>
              <tr class="bottom">
                <td>
                  <small class="gray">26.06.2023 13:54:55</small>
                  <br>
                  <span class="car-number">Р 342 АН 152</span>
                  <br><br>
                  <small>Документ: 23 345</small>
                </td>
                <td>
                  <small style="position: relative; top: 32px;">Время: 01:45</small>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
    </div>
  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'

export default {
  data() {
    return {
      showSearch: false,
      title: "Дашборд",

      options: {
        chart: {
          id: 'vuechart-example'
        },
        stroke: {
          curve: 'smooth',
          colors: ['#F26650'],
        },
        // markers: {
        //     size: 8,
        //     offsetX: 0,
        //     strokeColors: '#fff',
        //     strokeWidth: 5,
        //     colors: '#F26650',
        // },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: '#F26650',
          type: 'solid',
          opacity: 0.1,
        },
        xaxis: {
          categories: [20, 21, 22, 23, 24, 25, 26, 27]
        }
      },
      series: [{
        name: 'series-1',
        data: [30, 40, 45, 50, 49, 60, 70, 91]
      }]
    }
  },
  beforeMount() { document.title = this.title },
  methods: {
    clickCam(ip) {
      window.open('http://' + ip + '/action/stream?subject=mjpeg&user=admin&pwd=admin')
    }
  }
};
</script>
