<template>
  <div class="row justify-content-space-between" v-if="currentUser.groups.includes('Руководитель')">
    <div class="col-12">
      <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'labor'}" @click="currentTab = 'labor'">Охрана труда</button>
      <!-- <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'disp'}" @click="currentTab = 'disp'">Диспетчерская</button> -->
      <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'fact'}" @click="currentTab = 'fact'">Завод</button>
      <!-- <button class="btn-tab right-space-16" :class="{'btn-tab-active':currentTab === 'appeal'}" @click="currentTab = 'appeal'">Жалобы на вывоз</button> -->
    </div>
  </div>
  <div v-if="currentUser.groups.includes('Руководитель')">
    <HomeLabor v-if="currentTab === 'labor'"></HomeLabor>
    <!-- <HomeDisp v-if="currentTab === 'disp'"></HomeDisp> -->
    <HomeFactory v-if="currentTab === 'fact'"></HomeFactory>
    <!-- <HomeAppeal v-if="currentTab === 'appeal'"></HomeAppeal> -->
  </div>
  <div v-else>
    <HomeLabor v-if="currentUser.groups.includes('Охрана труда')"></HomeLabor>
    <!-- <HomeDisp v-if="currentUser.groups.includes('Диспетчерская')"></HomeDisp> -->
    <HomeFactory v-if="currentUser.groups.includes('Завод')"></HomeFactory>
    <!-- <HomeAppeal v-if="currentUser.groups.includes('QR КП')"></HomeAppeal> -->
  </div>
</template>

<script>
import HomeDisp from "@/components/Dashbord/HomeDisp.vue";
import HomeLabor from "@/components/Dashbord/HomeLabor.vue";
import HomeFactory from "@/components/Dashbord/HomeFactory.vue";
import HomeAppeal from "@/components/Dashbord/HomeAppeal.vue";
import 'vue-datepicker-next/index.css';

export default {
  data() {
    return {
      currentTab: 'labor'
    }
  },
  components: { HomeDisp, HomeLabor, HomeFactory, HomeAppeal },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    classActive() {
      return {
        active: this.isActive && !this.error,
        'text-danger': this.error && this.error.type === 'fatal'
      }
    }
  },
}
</script>
