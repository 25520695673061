<template>
  <div class="col-12">
    <router-view/>
  </div>
  
</template>

<script>

</script>

<style scoped>

</style>