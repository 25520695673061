<template>

  <div class="row">
    <div class="col-12 card col-laptop-12">
      <div class="row justify-content-space-between">
        <strong class="col-5 card-title">Количество жалоб по QR</strong>
        {{ getAppeals }}
        <div class="col-6 right">
          <!-- <button class="btn-tab btn-tab-active right-space-16">Неделя</button> -->
          <button class="btn-tab">Неделя</button>
        </div>
      </div>
      <div class="col-12">
        <apexchart type="area" :options="options" :series="series"></apexchart>
      </div>
    </div>

  </div>
</template>

<script>
import VueApexCharts from 'vue3-apexcharts'
import VueSpeedometer from "vue-speedometer"

export default {
  components: { VueSpeedometer },
  data() {
    return {
      styles: {
        padding: "15px",
        height: "150px",
      },
      appeals: '',
      showSearch: false,
      title: "Дашборд",

      options: {

        chart: {
          id: 'vuechart-example'
        },
        stroke: {
          curve: 'smooth',
          colors: ['#F26650', '#000000'],
        },
        // markers: {
        //     size: 8,
        //     offsetX: 0,
        //     strokeColors: '#fff',
        //     strokeWidth: 5,
        //     colors: '#F26650',
        // },
        dataLabels: {
          enabled: false
        },
        fill: {
          colors: '#F26650',
          type: 'solid',
          opacity: 0.1,
        },
        xaxis: {
          categories: ''
        }
      },

      series: [
        {
          name: 'Нижэкология',
          color: '#F26650',
          data: [20, 0, 5, 18, 0, 20, 0]
        }, 
    ]
    }
  },
  beforeMount() { document.title = this.title },
  computed: {
    Last7Days () {
      let lastdates = '0123456'.split('').map(function(n) {
          var d = new Date();
          d.setDate(d.getDate() - n);
          return (function(day, month, year) {
              return [day<10 ? '0'+day : day, month<10 ? '0'+month : month, year].join('.');
          })(d.getDate(), d.getMonth()+1, d.getFullYear());
      });
      this.options = {
          xaxis: {
              categories: lastdates.reverse()
          }
      }
    },
    getAppeals() {
      const byDate = (item) => {
        const itemDate = new Date(item.created_at);
        return (
          itemDate >= new Date('2024-03-05 00:00') &&
          itemDate <= new Date('2024-03-05 23:59:59')
        );
      };
      // return this.$store.state.appeals.filter((appeal) => new Date(appeal.created_at) > new Date('2024-03-06'))
      return this.$store.state.appeals.filter(byDate)
    }
  },
  methods: {
    clickCam(ip) {
      window.open('http://' + ip + '/action/stream?subject=mjpeg&user=admin&pwd=admin')
    }
  },
  async mounted() {

    await this.$store.dispatch("fetchAppeals", ["2023-01-02", "2024-12-31"]);
    
  }
};
</script>
